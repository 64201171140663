/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import BlogPopularSection from "./blogPopularSection";
import BlogPagination from "./blogPagination";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

const categories = {
  'News': '/blog/category/news/',
  'Cool Stuff': '/blog/category/cool-stuff/',
};

const Bayout = ({ children, hasPage, ...paginationOptions }) => {
  return (
    <div className="blog-layout-container container my-5 mx-auto py-lg-5 py-md-4 py-3 px-sm-0 px-4">
      <Navbar
        className="blog-layout-header px-0 align-items-lg-end align-items-center"
        container="fluid"
      >
        <NavbarBrand className='blog-layout-title d-flex' href="/blog">
          <span className="text-inter font-weight-bold mr-2">BitTorrent</span>
          <span className="text-inter font-weight-light">Blog</span>
        </NavbarBrand>

        <Nav className="blog-layout-topics" navbar>
          <div className="topics-list d-none d-lg-flex">
            {Object.entries(categories).map(([category, link], i) => (
              <NavItem key={category}>
                <NavLink
                  className={`text-inter ${i === Object.keys(categories).length - 1 ? "pr-0": ""}`}
                  href={link}
                >
                  {category}
                </NavLink>
              </NavItem>
            ))}
          </div>

          <UncontrolledDropdown className="topics-dropdown d-flex d-lg-none" nav>
            <DropdownToggle className="font-weight-bold text-inter" nav caret>
              Topics
            </DropdownToggle>
            <DropdownMenu right>
              {Object.entries(categories).map(([category, link], i) => (
                <DropdownItem className="text-inter" key={category}>
                  <a href={link}>{category}</a>
                </DropdownItem>
            ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>

      <hr className="blog-layout-hr" />

      <div className="blog-layout-body d-flex flex-column flex-lg-row">
        <div className="blog-layout-content">
          {children}
        </div>
        {hasPage &&
          <div className="d-lg-none d-flex justify-content-center">
            <BlogPagination {...paginationOptions} />
          </div>
        }
        <div className="blog-layout-sidebar d-flex flex-column">
          <BlogPopularSection />
        </div>
      </div>
      {hasPage &&
        <div className="d-lg-flex d-none justify-content-center mt-5">
          <BlogPagination {...paginationOptions} />
        </div>
      }
    </div>
  );
};

Bayout.propTypes = {
  hasPage: PropTypes.bool,
  numPages: PropTypes.number,
  currentPage: PropTypes.number,
  pageBasePath: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Bayout.defaultProps = {
  hasPage: false,
  numPages: 0,
  currentPage: 0,
  pageBasePath: '',
}

export default Bayout
