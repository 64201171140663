import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { removeTrailingSlash } from "../utils/gatsby-node-helpers";

import placeholder from "../images/blog/blog-placeholder.png";

const BlogListItem = ({ node }) => {
  const featuredImage = (node.featured_media)
    ? <img src={node.featured_media.source_url} alt="" className="img-fluid" />
    : <img src={placeholder} alt="" className="img-fluid" />;
  const postLink = removeTrailingSlash('/blog' + decodeURI(node.path));

  return (
    <div className="blog-list-item row">
      <Link className="blog-list-item-img col-md-5 col-12 mb-md-0 mb-3" to={postLink}>
        {featuredImage}
      </Link>

      <div className="blog-list-item-content d-flex flex-column col-md-7 col-12">
        <div className="blog-list-item-categories text-ptmono">
          {node.categories.map((category, index) => {
            const separator = (node.categories.length === index + 1) ? "" : ", ";
            // special case for greek mu letter for uTorrent
            const categoryName = category.name.startsWith("µ")
              ? category.name.substring(0, 1) + category.name.substring(1).toUpperCase()
              : category.name.toUpperCase();
            const categoryLink = removeTrailingSlash('/blog' + decodeURI(category.path));

            return (
              <div className="d-inline" key={node.id + index}>
                <Link to={categoryLink}>{categoryName}</Link>
                <span>{separator}</span>
              </div>
            );
          })}
        </div>
        <Link className="blog-list-item-title text-inter" to={postLink}>
          <span dangerouslySetInnerHTML={{ __html: node.title }} />
        </Link>
        <div className="blog-list-item-excerpt mb-1" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        <div className='blog-list-item-details d-flex text-ptmono'>
          <div className="text-uppercase mr-4">
            <span>By </span>
            <Link to={'/blog/author/scott-mcdonald'}>Scott McDonald</Link>
          </div>
          <div className="text-muted">{node.date}</div>
        </div>
      </div>
    </div>
  );
};

BlogListItem.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      })
    ).isRequired,
    featured_media: PropTypes.shape({
      source_url: PropTypes.string,
    }),
  }).isRequired
};

export default BlogListItem;
