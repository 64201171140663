import React, { Fragment } from "react";
import Bayout from "../../components/blogLayout";
import BlogListItem from "../../components/blogListItem";
import { graphql } from "gatsby";

export default ({ data, pageContext: { numPages, current } }) => {
  const listBasePath = "/blog/";

  return (
    <Bayout
      hasPage={true}
      numPages={numPages}
      currentPage={current}
      pageBasePath={listBasePath}
      showFirstPagePath={true}
    >
      <div className="blog-list d-flex flex-column">
        {data.allWordpressPost.edges.map(({ node }, i) =>
          <Fragment key={node.id}>
            <BlogListItem node={node} />
            {i < data.allWordpressPost.edges.length - 1 && <hr />}
          </Fragment>
        )}
      </div>
    </Bayout>
  )
};

export const query = graphql`
  query($limit:Int!, $skip:Int!) {
    allWordpressPost(sort: {fields: [date], order: [DESC]}, limit: $limit, skip: $skip, filter: {categories: {elemMatch: {name: {ne: "BTT Weekly Update"}}}}){
      edges {
        node {
          title
          excerpt
          path
          id
          date(formatString: "YYYY/MM/DD")
          categories {
            name
            path
          }
          featured_media {
          	source_url
         }
        }
      }
    }
  }
`;
