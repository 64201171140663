import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { removeTrailingSlash } from "../utils/gatsby-node-helpers";

const BlogPopularSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPost(sort: {fields: [date], order: [DESC]}, limit: 3, filter: {categories: {elemMatch: {name: {ne: "BTT Weekly Update"}}}})
      {
        edges {
          node {
            title
            path
            id
            featured_media {
              source_url
            }
          }
        }
      }
    }
  `);

  return (
    <div className="blog-popular-section">
      <div className="blog-section-title">Popular</div>
      <div className="blog-popular-items d-flex flex-column">
        {data.allWordpressPost.edges.map(({ node }) => {
          const featuredImage = (node.featured_media)
            ? <img src={node.featured_media.source_url} alt="" className="img-fluid" />
            : "";
          const postLink = removeTrailingSlash("/blog" + decodeURI(node.path));

          return (
            <div className="row" key={node.id}>
              <Link className="blog-popular-item-img col-5" to={postLink}>
                {featuredImage}
              </Link>
              <div className="d-flex flex-column col-7">
                <Link className="blog-popular-item-title text-inter mb-lg-2 mb-md-3 mb-2" to={postLink}>
                  {node.title}
                </Link>
                <span className="blog-popular-item-details text-ptmono text-uppercase text-muted">
                  <span>By </span>
                  <Link to={'/blog/author/scott-mcdonald'}>Scott McDonald</Link>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlogPopularSection;
