import React from "react";
import PropTypes from "prop-types";
import { removeTrailingSlash } from "../utils/gatsby-node-helpers";

const MIN_FLOATING_PAGES = 3;
const PADDING_FLOATING_PAGES = (MIN_FLOATING_PAGES - 1) / 2;

const BlogPagination = ({ numPages, currentPage, pageBasePath, showFirstPagePath }) => {
  const pagePath = removeTrailingSlash(pageBasePath) + '/page';

  const renderEllipsis = (num) => (
    <li key={`ellipsis-${num}`} className="page-item">
      <div className="page-link text-inter">{"..."}</div>
    </li>
  );

  const renderPageLink = (pageNum) => (
    <li key={`page-${pageNum}`} className={`page-item ${currentPage === pageNum ? 'active' : ''}`}>
      <a
        className="page-link text-inter"
        href={pageNum === 1 && !showFirstPagePath ? pageBasePath : `${pagePath}/${pageNum}`}
      >
        {pageNum}
      </a>
    </li>
  );

  const renderPages = () => {
    let pages = [];
    pages.push(renderPageLink(1));

    if (
      numPages > MIN_FLOATING_PAGES + 1
      && currentPage > MIN_FLOATING_PAGES
    ) {
      pages.push(renderEllipsis(1));
    }

    let startRange = currentPage - PADDING_FLOATING_PAGES > 1 ? currentPage - PADDING_FLOATING_PAGES : 1;
    let endRange = currentPage + PADDING_FLOATING_PAGES <= numPages ? currentPage + PADDING_FLOATING_PAGES : numPages;

    if (
      numPages > MIN_FLOATING_PAGES
      && (endRange - startRange + 1 < MIN_FLOATING_PAGES)
    ) {
      if (startRange === 1) {
        endRange = MIN_FLOATING_PAGES;
      } else if (endRange === numPages) {
        startRange = endRange - MIN_FLOATING_PAGES + 1;
      }
    }

    [...Array(endRange - startRange + 1).keys()]
      .map(i => i + startRange)
      .forEach(i => {
        if (i === 1 || i === numPages) return;
        pages.push(renderPageLink(i));
      });

    if (
      numPages > MIN_FLOATING_PAGES + 1
      && currentPage <= numPages - MIN_FLOATING_PAGES
    ) {
      pages.push(renderEllipsis(2));
    }

    if (numPages > 1) {
      pages.push(renderPageLink(numPages));
    }
    return pages;
  }

  return (
    <nav>
      <ul className="pagination d-flex align-items-end">
        {currentPage !== 1 && (
          <li className="page-item">
            <a
              className="page-link"
              href={currentPage - 1 === 1 && !showFirstPagePath ? pageBasePath : `${pagePath}/${currentPage - 1}`}
            >
              {"<"}
            </a>
          </li>
        )}
        {renderPages()}
        {currentPage !== numPages && (
          <li className="page-item">
            <a
              className="page-link"
              href={`${pagePath}/${currentPage + 1}`}
            >
              {">"}
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

BlogPagination.propTypes = {
  numPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageBasePath: PropTypes.string.isRequired,
  showFirstPagePath: PropTypes.bool, // show path as blog/page/1 or as blog/
};

BlogPagination.defaultProps = {
  showFirstPagePath: false,
};

export default BlogPagination;
